export class LeaveRequest {
  /**
   * @param {Object} leaverequest FastJson object.
   */
  constructor(leaverequest = {}) {
    this.id = leaverequest.id || null;
    this.RES_ID = leaverequest.attributes.RES_ID || null;
    this.employee_name = leaverequest.attributes.employee_name || null;
    this.leave_name = leaverequest.attributes.leave_name || null;
    this.submitter_name = leaverequest.attributes.submitter_name || null;
    this.leave_ID = leaverequest.attributes.leave_ID || null;
    this.start_date = leaverequest.attributes.start_date || null;
    this.end_date = leaverequest.attributes.end_date || null;
    this.status = leaverequest.attributes.status || false;
    if ( this.status == 0){
      this.status_name = "Pending";
    } else if ( this.status == 1){
      this.status_name = "Approved";
    } else if ( this.status == 2){
      this.status_name = "Rejected";
    }
    this.submitted_at = leaverequest.attributes.submitted_at || null;
    this.submitted_by = leaverequest.attributes.submitted_by || null;
    this.details = leaverequest.attributes.details || null;
  }
}
